import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { destroy } from 'redux-form';
import { isLength, isNumeric } from 'validator';
import { withRouter } from 'react-router-dom';
import { verifyUser } from '../../actions/auth';
import { INTERNAL_ERROR } from '../../constants/errors';
import { resendOtpCode } from '../../api/auth';
import { sendCodeByPhone } from '../../api/client'
import PayixButton from '../../containers/payix-button';

// Import actions
import { showMessageBar, showLoading, hideLoading } from '../../actions/app';

// Import styles
import '../../styles/modules/form.css';

class OtpForm extends Component {
  constructor(props) {
    super(props);

    if (this.props.validationCodeSeconds != undefined && this.props.validationCodeSeconds != '') {
        this.defaultResendUntil = parseInt(this.props.validationCodeSeconds)
    } else {
        this.defaultResendUntil = 180
    }
    
    this.digit1 = React.createRef();
    this.digit2 = React.createRef();
    this.digit3 = React.createRef();
    this.digit4 = React.createRef();
    this.digit5 = React.createRef();
    this.digit6 = React.createRef();

    this.state = {
      valid: false,
      digits: ['','','','','',''],
      resendUntil: this.defaultResendUntil
    }
  }

  componentDidMount() {
    this.digit1.current.focus();
    this.startCounter();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  /**
   * Start the timer that validates the lifetime of the otp code.
   */
  startCounter() {
    const comp = this;

    this.timerId = setInterval(function() {
      if (comp.state.resendUntil > 1) {
        comp.setState({resendUntil: (comp.state.resendUntil - 1)});
      } else {
        clearInterval(comp.timerId);
        comp.setState({resendUntil: 0});
      }
    }, 1000);
  }

  validDigit(str, length) {
    return isLength(str, {min: length, max: length}) && isNumeric(str);
  }

  keyDown = (e, current, next) => {
    const value = current.current.value;
    let digits = this.state.digits.slice();
    
    if(this.validDigit(value, 1)) {
      if(next) {
        next.current.focus();
      }
    } else {
      current.current.value = '';
    }

    digits[current.current.dataset.pos] = current.current.value;
    this.setState({digits});
    
    if(this.validDigit(digits.join(''), 6)) {
      this.setState({valid: true});
    } else {
      this.setState({valid: false});
    }
  }

  /**
   * Send the otp code again.
   */
  resendCode = () => {
    let values = this.props.values;
    this.props.showLoading();

    resendOtpCode(null, values.phoneno)
      .then(response => {
        let data = response.data;

        this.props.hideLoading();

        if (data.status === 1 && data.statusCode === 200) {
          this.props.showMessageBar({
            status: 'success',
            text: data.message
          });

          this.setState({resendUntil: this.defaultResendUntil}, () => {
            this.startCounter();
          });
        } else {
          this.props.showMessageBar({
            status: 'error',
            text: data.message
          });
        }
      })
      .catch(() => {
        this.props.hideLoading();
        this.props.showMessageBar({
          status: 'error',
          text: INTERNAL_ERROR
        });
      });
  }

  handleVerifyUser = () => {
    const otp = this.state.digits.join('');

    this.props.verifyUser({...this.props.values, otp})
      .then((isUserVerified) => {
        if (isUserVerified === true) {
          this.props.destroy('signup');
        } else if (isUserVerified === 'verified_email_required' && this.props.verifyEmailForBpLogin === '1') { // Return to home view in case the email needs to be verified before login
          this.props.history.push('/home');
        }
      })
      .catch(() => {});
  }

  resendCodeCall = () => {
      let values = this.props.values;
      this.props.showLoading();
      
      sendCodeByPhone(values.phoneno)
        .then(response => {
          let data = response.data;

          this.props.hideLoading();

          if (data.status === 1 && data.statusCode === 200) {
            this.props.showMessageBar({
              status: 'success',
              text: data.message
            });

            this.setState({resendUntil: this.defaultResendUntil}, () => {
              this.startCounter();
            });
          } else {
            this.props.showMessageBar({
              status: 'error',
              text: data.message
            });
          }
        })
        .catch(() => {
          this.props.hideLoading();
          this.props.showMessageBar({
            status: 'error',
            text: INTERNAL_ERROR
          });
        });
      
  }

  render () {
    const { otpMessage } = this.props;
    const valid = this.state.valid;

    return (
      <div className="sign-in-form__container">
        <div className="logo-img__container">
          <img className="logo-img" src={this.props.logo} alt="Logo"/>
        </div>  

        <h2 className="form__title">{ this.props.whiteLabel.labels['Verify Phone Number'] }</h2>
        
        <p>
          <span className="em-vertical-margin">
            {otpMessage.length > 0 && 
              <span>{otpMessage}&nbsp;
                <Link to="/signup"><span className="gothammedium-font">({this.props.whiteLabel.labels['Edit']})</span></Link>
              </span>
            }
          </span>
        </p>

        <div className="otp-form__digits-container">
          <input onKeyUp={(e)=>this.keyDown(e, this.digit1, this.digit2)} data-pos="0" ref={this.digit1} className="otp-digit" type="number"/>
          <input onKeyUp={(e)=>this.keyDown(e, this.digit2, this.digit3)} data-pos="1" ref={this.digit2} className="otp-digit" type="number"/>
          <input onKeyUp={(e)=>this.keyDown(e, this.digit3, this.digit4)} data-pos="2" ref={this.digit3} className="otp-digit" type="number"/>
          <input onKeyUp={(e)=>this.keyDown(e, this.digit4, this.digit5)} data-pos="3" ref={this.digit4} className="otp-digit" type="number"/>
          <input onKeyUp={(e)=>this.keyDown(e, this.digit5, this.digit6)} data-pos="4" ref={this.digit5} className="otp-digit" type="number"/>
          <input onKeyUp={(e)=>this.keyDown(e, this.digit6)} ref={this.digit6} data-pos="5" className="otp-digit" type="number"/>
        </div>

        {this.state.resendUntil > 0 && 
          <p className="em-vertical-margin text-center">
            <span>{this.props.whiteLabel.labels['Resend code will enable in']} {this.state.resendUntil} {this.props.whiteLabel.labels['seconds']}</span>
          </p>
        }
          
        <div className="form-group">
          <PayixButton className="button--full" disabled={!valid} onClick={this.handleVerifyUser}>
              {this.props.whiteLabel.labels['Verify']}
          </PayixButton>
        </div>
        
        { this.state.resendUntil === 0 && 
          <p className="em-vertical-margin text-center">
            <span className="form-link gmedium-common margin-right-10" onClick={this.resendCode}>{this.props.whiteLabel.labels['Resend Code?']}</span>
            { this.props.validationCodeCallOption == '1' && 
              <span>
                  <a className="form-link gmedium-common"
                        onClick={this.resendCodeCall}>
                        {this.props.whiteLabel.labels['RECEIVE A CALL']}
                  </a>
              </span>
            }
          </p>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth, whiteLabel } = state;

  return {
    verifyEmailForBpLogin: state.client.data.verifyEmailForBpLogin,
    logo: state.client.data.imageName,
    values: auth.formValues,
    otpMessage: auth.otpMessage,
    whiteLabel: whiteLabel
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    verifyUser, showLoading, hideLoading, showMessageBar, destroy
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OtpForm));
