import React, { Component } from 'react';

// Import components
import Cropper from 'react-cropper';
import UploadPhotoInput from './application/upload-photo-input';
import PayixButton from '../containers/payix-button';

// Import styles
import 'cropperjs/dist/cropper.css';

class UploadPhoto extends Component {
  state = {
    photo: '',
    cropResult: null,
    cropResultOnEditing: null,
    isEditing: false,
    disabled: true
  }

  constructor(props) {
    super(props);
    this.cropper = React.createRef();
  }

  _crop = () => {
    let croppedCanvas = this.cropper.current.getCroppedCanvas();

    if (typeof croppedCanvas === 'undefined') {
      return;
    }

    this.setState({
      cropResultOnEditing: croppedCanvas.toDataURL(),
    });
  }

  handleSelectPhoto = (event) => {
    let file = event.target.files[0];
    if (file) {
      let oFReader = new FileReader();
      oFReader.onload = (event) => {
        this.setState({
          photo: event.currentTarget.result,
          isEditing: true,
        });
      };
      oFReader.readAsDataURL(file);
    }
  }

  handleUpload = () => {
    this.props.onUpload(this.state.cropResult);
  }

  handleCropToggle = () => {
    this.setState({
      isEditing: !this.state.isEditing,
    })
  }
  
  handleRemoveUploadPhoto = () => {
    this.setState({
      cropResult: null,
      photo: null,
      disabled: true
    })
  }

  handleUpdateCropPhoto = () => {
    this.setState({
      cropResult: this.state.cropResultOnEditing,
      isEditing: false,
      disabled: false
    })
  }

  handleCancelCropPhoto = () => {
    this.setState({
      photo: this.state.cropResult && this.state.photo,
      isEditing: !this.state.isEditing,
    })
  }

  /**
   * Render the component view.
   */
  render() { 
    const { isEditing, photo, cropResultOnEditing, cropResult } = this.state;
    const { color, whiteLabel, aspectRatio, onCancel } = this.props;

    return (
      <div>
        <div className="modal__header">
          <h3 className="modal-title">{this.props.whiteLabel.labels['Image Upload']}</h3>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            <form encType="multipart/form-data">
              {photo ? (
                <React.Fragment>
                  <div className="image-preview">
                    {isEditing ? (
                      <Cropper
                        ref={this.cropper}
                        src={photo}
                        style={{height: 200, width: '100%'}}
                        aspectRatio={aspectRatio}
                        cropBoxResizable={false}
                        dragMode="move"
                        guides={false}
                        movable={false}
                        rotatable={false}
                        scalable={false}
                        toggleDragModeOnDblclick={false}
                        zoomable={true}
                        zoomOnTouch={true}
                        zoomOnWheel={true}
                        crop={this._crop.bind(this)} />
                    ) : <img style={{ width: '100%', minHeight: '200px' }} src={cropResult} alt="" />
                    }
                    <div className="image-preview__actions">
                        <div className=" margin-right-10">
                          {isEditing ? <i onClick={this.handleCancelCropPhoto} className="fas fa-times image-preview__action image-preview__action--negative">
                          </i> : <i onClick={this.handleCropToggle} className="fas fa-pencil-alt image-preview__action" style={{ backgroundColor: color }}>
                          </i>}
                        </div>
                        <div className="margin-right-10">
                          {isEditing ? <i onClick={this.handleUpdateCropPhoto} className="fas fa-check image-preview__action image-preview__action--positive ">
                          </i> : <i onClick={this.handleRemoveUploadPhoto} className="fas fa-trash-alt image-preview__action" style={{ backgroundColor: color }}>
                          </i>}
                        </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <UploadPhotoInput 
                  label={whiteLabel.labels['Choose an image']} 
                  onChange={this.handleSelectPhoto}
                />
              )
            }

              

              <div className="form-group form-group--row-layout margin-top-20">
                <PayixButton className="size-50per margin-right-10" onClick={ onCancel }>
                    {whiteLabel.labels['Cancel']}
                </PayixButton>

                <PayixButton className="size-50per" id="upload_file" onClick={ this.handleUpload } disabled={this.state.disabled}>
                    {whiteLabel.labels['Upload']}
                </PayixButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
 
export default UploadPhoto;