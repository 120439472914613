import React, { Component } from 'react';
import { Application } from '../../core/app';
class AppFooter extends Component {
  /**
   * Render the component view.
   */
  render() {
    let footerContent = <footer className="app-footer">
        <div className="app-footer__content">
          <p className="app-footer__copyright gothambook-font">
          {this.props.whiteLabel.labels['Copyright Text']}
          </p>
          <ul className="app-footer__legal-content">
            {(this.props.customUrl1 != null && this.props.customUrl1 != '') ? 
              <li className="app-footer__legal-content-item app-footer__legal-content-terms">
                  <a className="app-footer__legal-content-link gothambook-font" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      href={this.props.customUrl1}>
                      {this.props.customUrl1Text}
                  </a>
              </li>
              : <li></li>}
            {(this.props.customUrl2 != null && this.props.customUrl2 != '') ? 
              <li className="app-footer__legal-content-item app-footer__legal-content-terms">
                  <a className="app-footer__legal-content-link gothambook-font" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      href={this.props.customUrl2}>
                      {this.props.customUrl2Text}
                  </a>
              </li>
              : <li></li>}
            <li className="app-footer__legal-content-item app-footer__legal-content-terms">
              <span className="app-footer__legal-content-link standard-link gothambook-font" onClick={this.props.onShowTerms}>
                {this.props.whiteLabel.labels['Terms & Conditions']}
              </span>
            </li>
            <li className="app-footer__legal-content-item app-footer__legal-content-privacy">
              <a 
                className="app-footer__legal-content-link gothambook-font" 
                href={this.props.privacyPolicy}
                rel="noopener noreferrer" 
                target="_blank"
              >
              {this.props.whiteLabel.labels['Privacy Policy']}
              </a>
            </li>
          </ul>
        </div>
      </footer>;
    if (this.props.customFooterContent !== '') {
      footerContent = <footer className="app-footer" dangerouslySetInnerHTML={{__html: this.props.customFooterContent}}></footer>;
    }
    return footerContent;
  }
}

export default AppFooter;
