import React, { Component } from 'react';

// Import components
import PayixButton from '../../../containers/payix-button';

// Import assets
import warningIcon from '../../../assets/images/warning.png';
import successIcon from '../../../assets/images/checked.png';

class InfoModal extends Component {
  /**
   * Render the icon on the header.
   */
  renderIcon() {
    switch (this.props.type) {
      case 'confirmation':
        return <img src={warningIcon} alt="" />
      case 'success':
        return <img src={successIcon} alt="" />
      default:
        return null;
    }
  }

  /**
   * Render one button after the content of the modal
   */
  renderOneButton() {
    return (
      <div className="form-group">
        <PayixButton className="button--full" type="button" >
          {this.props.buttonText}
        </PayixButton>
      </div>
    )
  }

  /**
   * Render one button to the left and the other to the right after the content of the modal.
   */
  renderTwoButtons() {
    return (
      <div className="form-group form-group--row-layout">
        <PayixButton className="size-50per margin-right-10" onClick={this.props.leftButtonHandler}>
            {(this.props.whiteLabel.labels['Cancel'] != undefined)? this.props.whiteLabel.labels['Cancel'] : this.props.leftButtonText}
        </PayixButton>

        <PayixButton className="size-50per margin-right-10" onClick={this.props.rightButtonHandler}>
            {(this.props.whiteLabel.labels['Confirm'] != undefined)? this.props.whiteLabel.labels['Confirm'] : this.props.rightButtonText}
        </PayixButton>
      </div>
    )
  }

  /**
   * Render the component view.
   */
  render() {
    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            {this.renderIcon()}

            {this.props.headerText && 
              (
                <p className="modal-header-text">
                  {this.props.headerText}
                </p>
              )
            }
          </div>
        </div>
        
        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            <p className="modal-paragraph">
              {this.props.content}
            </p>
            {(!this.props.noButton) && (this.props.button
              ? this.renderOneButton()
              : this.renderTwoButtons())}
          </div>
        </div>
      </div>
    )
  }
}

InfoModal.defaultProps = {
  type: 'confirmation',
  leftButtonText: 'Cancel',
  rightButtonText: 'Confirm'
}
 
export default InfoModal;