import React, { Component } from 'react';
import { Application } from '../core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoanAccountService from '../services/loan-account';
import { withRouter } from 'react-router-dom';
import { requestDeleteScheduledPayment, editScheduledPayment } from '../api/payment';
import { findById, findPaymentMethodByType } from '../helpers/app';
import moment from 'moment';
import { PAYMENT_TYPE_CARD, PAYMENT_TYPE_ACH, PAYMENT_TYPE_PAD } from '../constants/app';

// Import actions
import { showLoading, hideLoading, setPaymentMethod, setConvenienceFee } from '../actions/app';
import { fetchLoanAccounts, setSelectedLoanAccount } from '../actions/loan-account';
import { fetchPaymentCards, getTransactionMargin, getClientImages, getClientDetails } from '../actions/client';
import { setOption, fetchScheduledPayments, setScheduledPayments } from '../actions/schedule';

// Import components
import AddPaymentMethodModal from '../components/add-payment-method-modal';
import CongratulationsScheduleModal from '../components/schedule/congratulations-modal';
import DeleteScheduledPaymentModal from '../components/schedule/delete-scheduled-payment-modal';
import DeleteRecurringScheduledPaymentModal from '../components/schedule/delete-recurring-scheduled-payment-modal';
import EditRecurringScheduleModal from '../components/schedule/edit-recurring-schedule-modal';
import EditScheduleAchTerms from '../components/schedule/edit-schedule-ach-terms';
import EditSchedulePadTerms from '../components/schedule/edit-schedule-pad-terms';
import EditScheduleCardTerms from '../components/schedule/edit-schedule-card-terms';
import EditScheduleModal from '../components/schedule/edit-schedule-modal';
import LoanAccount from '../components/application/loan-account';
import Modal from '../components/application/modal';
import ScheduleReceipt from '../components/schedule/schedule-receipt';
import PaymentMethods from '../components/application/payment-methods';
import PaymentTypes from '../components/application/page-options/payment-types';
import ScheduleForm from '../components/application/loan-account/schedule-form';
import Status from '../components/application/page-options/status';
import SortBy from '../components/application/page-options/sort-by';

// Import styles
import '../styles/layout/schedule.css';
import '../styles/modules/schedule/index.css';

class Schedule extends Component {
  state = {
    isSuccessScheduleModalOpen: false,
    isDeleteScheduledPaymentModalOpen: false,
    isDeleteRecurringScheduledPaymentModalOpen: false,
    isModalOpen: false,
    modal: null,
    modalWidth: '500px',
    scheduleDate: null,
    paymentFrequency: 'onlyonce',
    amount: '',
    paymentsNumber: null,
    selectedPaymentMethod: null,
    scheduleDetails: null,
    recurring: 0,
  }

  selectedScheduledPayment = null;
  scheduleEditForm = null;

  /**
   * Fetch the initial data.
   */
  componentDidMount() {
    this.fetchInitialData();
  }

  /**
   * This hook is fired when component is updated.
   * 
   * @param {*} prevProps 
   */
  componentDidUpdate(prevProps) {
    let { selectedLoanAccount, paymentType } = this.props;

    if (!selectedLoanAccount || !prevProps.selectedLoanAccount) {
      return ;
    }

    /**
     * If the filters or the selected loan account has changed we request 
     * the scheduled payments again.
     */
    
    if (prevProps.selectedLoanAccount.id !== selectedLoanAccount.id) {
      this.fetchScheduledPayments();
    }

    if (this.hasPropChanged('status', prevProps) ||
      this.hasPropChanged('paymentType', prevProps) ||
      this.hasPropChanged('sortBy', prevProps)) {

      if (paymentType !== -1) {
        this.fetchScheduledPayments();
      }
    }
  }

  /**
   * Fetch the loan accounts and the payment methods.
   */
  fetchInitialData = () => {
    let { 
      fetchLoanAccounts, fetchPaymentCards, showLoading, hideLoading, getTransactionMargin, getClientImages, getClientDetails
    } = this.props;
    
    showLoading();

    return Promise.all([fetchLoanAccounts(), fetchPaymentCards(), getTransactionMargin(), getClientImages(), getClientDetails()])
      .then(() => {
        this.fetchScheduledPayments();
        this.resetForm();
      })
      .catch(() => {
        hideLoading();
      });
  }

  /**
   * Reset the schedule payment form.
   */
  resetForm = () => {
    this.props.setConvenienceFee(null);
    this.setState({
      selectedPaymentMethod: null
    });
  }

  /**
   * Close the modal.
   */
  closeModal = () => {
    this.setState({
      isModalOpen: false,
      modal: null,
      modalWidth: '500px'
    });
  }

  /**
   * Check if the value of a property has changed since its last render.
   * 
   * @param {*} prop 
   */
  hasPropChanged(prop, prevState) {
    if (prevState[prop] !== this.props[prop]) {
      return true;
    }

    return false;
  }

   /**
   * Display or hidden a specific modal.
   * 
   * @param modalId
   */
  toggleModal(modalId) {
    this.setState((prevState) => {
      let key = `is${modalId}ModalOpen`;
      let newState = {};

      newState[key] = !prevState[key];

      return newState;
    });
  }

  /**
   * Reset the vars associates to the edit schedule proccess.
   */
  resetEditFields = () => {
    this.scheduleEditForm = null;
    this.selectedScheduledPayment = null;
  }

  /**
   * Handle on closing ach terms.
   */
  handleCloseAchTerms = () => {
    this.resetEditFields();
  }

  /**
   * Prepare and execute the action to get the schedule payments.
   */
  fetchScheduledPayments = () => {
    let { selectedLoanAccount, sortBy, status, paymentType, fetchScheduledPayments } = this.props;

    if (!selectedLoanAccount) {
      this.props.hideLoading();
      return ;
    }

    let data = {
      loanId: selectedLoanAccount.id,
      sortBy: sortBy,
      type: status,
      paymentType: (paymentType) ? paymentType : ''
    };

    fetchScheduledPayments(data);
  }

  /**
   * Get the information of the payment method used for a specific scheduled payment.
   * 
   * @param {*} scheduledPayment 
   */
  getPaymentMethod(scheduledPayment) {
    let paymentMethod = null;

    if (scheduledPayment.paymentType === PAYMENT_TYPE_CARD) {
      paymentMethod = scheduledPayment.cardMethod;
    } else if (scheduledPayment.paymentType === PAYMENT_TYPE_ACH) {
      paymentMethod = scheduledPayment.achMethod;
     }else if (scheduledPayment.paymentType === PAYMENT_TYPE_PAD) {
      paymentMethod = scheduledPayment.padMethod;
    }

    return paymentMethod;
  }

  /**
   * Map the response back when scheduling a payment and create props that are passed to the success modal.
   * 
   * @param {*} response 
   */
  mapResponseToPropsScheduleSuccessModal() {
    let { scheduleDetails } = this.state;
    let props = {};

    if (scheduleDetails !== null) {
      props = {
        frequency: scheduleDetails.paymentText,
        amount: scheduleDetails.pmtAmt,
        paymentMethod: (scheduleDetails.paymentType === 1) ? scheduleDetails.cardMethod : scheduleDetails.achMethod,
        date: scheduleDetails.nextExecutionDate
      }
    }

    return props;
  }

  /**
   * Handle when payment method changes.
   * 
   * @param key - The setted key for the payment method.
   */
  handleChangePaymentMethod = (index, added_recently = false) => {

    //If the payment method was just added, it needs to be selected automatically
    if (added_recently) {
      var self = this
      var checkLoading = setInterval(function(){
        var { loading } = self.props;
        if (loading == false) {
          let paymentMethod = self.props.cards[index];
          let pmtType = self.state.paymentFrequency === 'onlyonce' ? 1 : 2;
          self.props.setPaymentMethod(paymentMethod.cardBrand, self.props.selectedLoanAccount.id, paymentMethod.paymentType, pmtType);
          self.setState({
            selectedPaymentMethod: paymentMethod
          });
          clearInterval(checkLoading);
        }
      }, 500); 
      return;
    }
    
    let paymentMethod = this.props.cards[index];
    let pmtType = this.state.paymentFrequency === 'onlyonce' ? 1 : 2;
    
    if (this.state.amount == '' && this.props.client.autoFillSchOnetime) {
      this.setState({
        amount:  parseFloat(this.props.selectedLoanAccount.regularPmtAmt).toFixed(2),
        selectedPaymentMethod: paymentMethod
      }, () => {
        this.props.setPaymentMethod(paymentMethod.cardBrand, this.props.selectedLoanAccount.id, paymentMethod.paymentType, pmtType, paymentMethod.id, this.state.amount);
      }); 
    } else {
      this.props.setPaymentMethod(paymentMethod.cardBrand, this.props.selectedLoanAccount.id, paymentMethod.paymentType, pmtType, paymentMethod.id, this.state.amount);
      this.setState({
        selectedPaymentMethod: paymentMethod
      });
    }

  }

  /**
   * Controls the amount.
   * 
   * @param value The new value the amount.
   */
  handleChangeAmount = (value) => {
    this.setState({
      amount: value
    });
  }

  /**
   * Control the value of the schedule date.
   * 
   * @param selectedDated An array with the selected dates.
   * @param dateStr - The date in string format.
   */
  handleScheduleDate = (selectedDates, dateStr) => {
    this.setState({
      scheduleDate: dateStr
    });
  }

  /**
   * Handle the event when an user select a payment frequency.
   * 
   * @event
   */
  handleChangePaymentFrequency = (paymentFrequency) => {
    let amount = '';

    let paymentMethod = this.state.selectedPaymentMethod;
    let pmtType = paymentFrequency === 'onlyonce' ? 1 : 2;

    //Set new payment method only when there is one already selected
    if (paymentMethod) {
      this.props.setPaymentMethod(paymentMethod.cardBrand, this.props.selectedLoanAccount.id, paymentMethod.paymentType, pmtType);
    }

    if (paymentFrequency !== 'onlyonce') {
      amount = this.props.selectedLoanAccount.regularPmtAmt;
    }

    this.setState({
      amount: amount,
      paymentFrequency
    })
  }

  /**
   * Handle the event to select payment type.
   */
  handleSelectPaymentType = (values) => {
    let paymentType = null;

    if (values.check && !values.card) {
      paymentType = 1;
    } else if (!values.check && values.card) {
      paymentType = 2;
    }

    // If no method of payment was chosen we do not make any request, 
    // we only delete the entire array of scheduled payments.
    if (!values.check && !values.card) {
      paymentType = -1;
      this.props.setScheduledPayments([]);
    }

    this.props.setOption({
      key: 'paymentType',
      value: paymentType
    });
  }

  /**
   * Handle the event to select a sort by option.
   */
  handleSelectSortBy = (e) => {
    let sortBy = e.target.value;

    this.props.setOption({
      key: 'sortBy',
      value: sortBy
    });
  }

  /**
   * Handle the event to select a status option.
   */
  handleSelectStatus = (e) => {
    let status = e.target.value;

    this.props.setOption({
      key: 'status',
      value: status
    });
  }

  /**
   * Close the success schedule modal.
   */
  closeSuccessScheduleModal = () => {
    this.toggleModal('SuccessSchedule');
  }

  /**
   * Close the delete schedule modal.
   */
  closeDeleteScheduledPaymentModal = () => {
    this.toggleModal('DeleteScheduledPayment');
  }

    /**
   * Close the delete schedule modal.
   */
  closeDeleteRecurringScheduledPaymentModal = () => {
    this.toggleModal('DeleteRecurringScheduledPayment');
  }

  /**
   * Close the Ach terms and conditions modal.
   */
  closeAchTermsAndConditionsModal = () => {
    this.toggleModal('AchTermsAndConditions');
  }

  /**
   * Find the info for selected scheduled payment.
   * 
   * @param {*} id 
   */
  findScheduledPayment(id) {
    return findById(id, this.props.scheduledPayments);
  }

  /**
   * Display the modal with the form to edit a scheduled payment.
   * 
   * @param id
   */
  handleEdit = (id) => {
    let scheduledpayment = this.findScheduledPayment(id);

    if (scheduledpayment) {
      this.selectedScheduledPayment = scheduledpayment;

      this.setState({
        isModalOpen: true,
        modal: 'EDIT_SCHEDULED_PAYMENT'
      });
    }
  }

  /**
   * Display the modal with the form to edit a scheduled payment.
   * 
   * @param id
   */
  handleRecurringEdit = (id) => {
    let scheduledpayment = this.findScheduledPayment(id);
    
    if (scheduledpayment) {
      this.selectedScheduledPayment = scheduledpayment;

      this.setState({
        isModalOpen: true,
        modal: 'EDIT_RECURRING_SCHEDULED_PAYMENT'
      });
    }
  }

  /**
   * Display the modal confirmation to delete a scheduled payment.
   * 
   * @param id
   */
  handleDelete = (id) => {
    let scheduledpayment = this.findScheduledPayment(id);

    if (scheduledpayment) {
      this.selectedScheduledPayment = scheduledpayment;
      this.toggleModal('DeleteScheduledPayment');
    }
  }

  handleRecurringDelete = (id) => {
    let scheduledpayment = this.findScheduledPayment(id);

    if (scheduledpayment) {
      this.selectedScheduledPayment = scheduledpayment;
      this.toggleModal('DeleteRecurringScheduledPayment');
    }
  }

  /**
   * Handle the event to delete a scheduled payment.
   * 
   * @param id
   */
  handleDeleteScheduledPayment = () => {
    let recurringId = null
    if(this.state.isDeleteRecurringScheduledPaymentModalOpen){
      this.toggleModal('DeleteRecurringScheduledPayment');
      recurringId = this.selectedScheduledPayment.recurringId
    }else{
      this.toggleModal('DeleteScheduledPayment');
    }
    this.props.showLoading();

    requestDeleteScheduledPayment({
      scheduleId: this.selectedScheduledPayment.id,
      recurringId: recurringId
    }).then((response) => {
        this.props.hideLoading();
        this.selectedScheduledPayment = null;
        let dataResponse = response.data;

        if (dataResponse.statusCode === 200 && dataResponse.status === 1) {
          Application.showSuccessMessage(dataResponse.message)
          this.fetchInitialData();
        }
      }).catch(() => {
        this.selectedScheduledPayment = null;
        this.props.hideLoading();
        Application.showInternalError();
      });
  }

  /**
   * Handle the event to edit a scheduled payment.
   * 
   * @param values The rest values.
   */
  handleEditScheduledPayment = (values) => {
    this.scheduleEditForm = values;

    if (values.paymentMethod.paymentType === PAYMENT_TYPE_CARD) {
      // Show the terms and conditions modal.
      this.setState({
        isModalOpen: false,
        modal: null
      }, () => {
        this.setState({
          isModalOpen: true,
          modal: 'EDIT_SCHEDULE_CARD_TERMS',
          modalWidth: '750px'
        });
      });
    } else if (values.paymentMethod.paymentType === PAYMENT_TYPE_ACH) {
      // Show the terms and conditions modal.
      this.setState({
        isModalOpen: false,
        modal: null
      }, () => {
        this.setState({
          isModalOpen: true,
          modal: 'EDIT_SCHEDULE_ACH_TERMS',
          modalWidth: '750px'
        });
      });
    } else if (values.paymentMethod.paymentType === PAYMENT_TYPE_PAD) {
      // Show the terms and conditions modal.
      this.setState({
        isModalOpen: false,
        modal: null
      }, () => {
        this.setState({
          isModalOpen: true,
          modal: 'EDIT_SCHEDULE_PAD_TERMS',
          modalWidth: '750px'
        });
      });
    }
  }

  editScheduledPayment = () => {
    this.props.showLoading();
    let scheduleEditForm = this.scheduleEditForm;

    editScheduledPayment({
      achTransactionFee: '',
      cardTransactionFee: 0,
      id: scheduleEditForm.paymentMethod.id,
      loanAmount: scheduleEditForm.amount,
      loanId: this.props.selectedLoanAccount.id,
      paymentType: scheduleEditForm.paymentMethod.paymentType,
      scheduleDate1: scheduleEditForm.scheduleDate,
      scheduleDate2: '',
      recurringId: scheduleEditForm.recurringId,
      scheduleId: this.selectedScheduledPayment.id,
      type: this.selectedScheduledPayment.type
    }).then((response) => {
      let data = response.data;

      this.props.hideLoading();
      this.closeModal();

      if (data.status === 1 && data.statusCode === 200) {
        Application.showSuccessMessage(data.message);
        this.fetchInitialData();
      } else {
        Application.showErrorMessage(data.message);
      }

      this.resetEditFields();
    }).catch(() => {
      this.props.hideLoading();
      this.closeModal();
      this.resetEditFields();
    });
  }

  /**
   * handle when user press cancel on ach terms modal.
   */
  handleCancelEditScheduleAchTerms = () => {
    this.setState({
      isModalOpen: false,
      modal: null,
      modalWidth: '500px'
    }, () => {
      if (this.state.recurring) {
        this.setState({
          isModalOpen: true,
          modal: 'EDIT_RECURRING_SCHEDULED_PAYMENT'
        })
      } else {
        this.setState({
          isModalOpen: true,
          modal: 'EDIT_SCHEDULED_PAYMENT'
        })
      }
    });
  }

  /**
   * Unselected the current payment method.
   */
  clearPaymentMethod = () => {
    this.setState({
      selectedPaymentMethod: null
    });
  }

  /**
   * Handle the event when a schedule payment is successful.
   */
  handleSuccessSchedule = () => {
    this.props.setConvenienceFee(null);
    this.clearPaymentMethod();
    this.fetchInitialData();
  }

  handleAddPaymentMethod = () => {
    this.setState({
      isModalOpen: true,
      modal: 'ADD_PAYMENT_METHOD'
    });
  }

  /**
   * Close the modal to add a payment method and reload the payment methods.
   */
  handleAddPaymentMethodSuccess = () => {
    this.closeModal();
    this.fetchInitialData();
  }

  /**
   * Handler for when a new account has linked.
   * 
   * @param id
   */
  handleLinkLoanSuccess = (id) => {
    sessionStorage.removeItem('acceptedBkUdfPopup')
    this.fetchInitialData()
      .then(() => {
        this.props.setSelectedLoanAccount(id);
      })
      .catch(() => {
        Application.showInternalError();
      });
  }

  /**
   * Check if the account has at least one scheduled recurring payment.
   */
  loanHasRecurring() {
    for (let schedulePayment of this.props.scheduledPayments) {
      if (schedulePayment.type === '2' && schedulePayment.status === '0') {
        return true;
      }
    }

    return false;
  }

  getHandlerOnClosing = () => {
    switch (this.state.modal) {
      case 'EDIT_SCHEDULED_PAYMENT' :
        return this.resetEditFields;
      case 'EDIT_RECURRING_SCHEDULED_PAYMENT' :
        return this.resetEditFields;
      case 'EDIT_SCHEDULE_CARD_TERMS':
        return this.resetEditFields;
      case 'EDIT_SCHEDULE_ACH_TERMS' :
        return this.resetEditFields;
      case 'EDIT_SCHEDULE_PAD_TERMS' :
        return this.resetEditFields;
      default:
        return null;
    }
  }

  /**
   * Renders a message indicating that there are no scheduled payments.
   */
  renderEmptyScheduledPayments() {
    return (
      <div className="empty-message">
        <i className="fas fa-info-circle empty-message__icon"></i>
        <p className="empty-message__text">{this.props.whiteLabel.labels['No scheduled payments']}</p>
      </div>
    )
  }

  renderModalContent() {   
    switch (this.state.modal) {
      case 'ADD_PAYMENT_METHOD' :
        return <AddPaymentMethodModal
          hideCardsForBorrowers={this.props.hideCardsForBorrowers}
          onSuccess={this.handleAddPaymentMethodSuccess}
          changePayment={this.handleChangePaymentMethod} />
      case 'EDIT_SCHEDULED_PAYMENT' :
        this.state.recurring = 0;
        let amount = this.selectedScheduledPayment.pmtAmt;
        let convenienceFee =  this.selectedScheduledPayment.convenienceFee;
        let scheduleDate = this.selectedScheduledPayment.nextExecutionDate.date;
        let paymentMethod = findPaymentMethodByType(
          this.getPaymentMethod(this.selectedScheduledPayment).id, 
          this.selectedScheduledPayment.paymentType, 
          this.props.cards
        );
        if (this.scheduleEditForm) {
          amount = this.scheduleEditForm.amount;
          paymentMethod = this.scheduleEditForm.paymentMethod;
          scheduleDate = this.scheduleEditForm.scheduleDate;
        }
        
        return <EditScheduleModal 
          convenienceFee={convenienceFee}
          amount={amount}
          scheduleDate={scheduleDate}
          loanNumber={this.props.selectedLoanAccount.loanNo}
          loanId={this.props.selectedLoanAccount.id}
          showLoading={showLoading}
          hideLoading={hideLoading}
          regularPmtAmt={this.props.selectedLoanAccount.regularPmtAmt}
          borSchedMaxDays={this.props.client.borSchedMaxDays}
          restrictSchMaxDaysForFrequencies={this.props.client.restrictSchMaxDaysForFrequencies}
          repeatingType={this.selectedScheduledPayment.repeatingType}
          paymentMethods={this.props.cards}
          selectedPaymentMethod={paymentMethod}
          borSchedRecurringAchOnly={this.props.client.borSchedRecurringAchOnly}
          borAchRealtimePmts={this.props.client.borAchRealtimePmts}
          borAchScheduleBizDays={this.props.client.borAchScheduleBizDays}
          frequency={parseInt(this.selectedScheduledPayment.type)}
          onSubmit={this.handleEditScheduledPayment}
          whiteLabel={this.props.whiteLabel} />
      case 'EDIT_RECURRING_SCHEDULED_PAYMENT' :
        this.state.recurring = 1;
        let ramount = this.selectedScheduledPayment.pmtAmt;
        let rconvenienceFee =  this.selectedScheduledPayment.convenienceFee;
        let rscheduleDate = this.selectedScheduledPayment.nextExecutionDate.date;
        let rpaymentMethod = findPaymentMethodByType(
          this.getPaymentMethod(this.selectedScheduledPayment).id, 
          this.selectedScheduledPayment.paymentType, 
          this.props.cards
        );

        if (this.scheduleEditForm) {
          amount = this.scheduleEditForm.amount;
          paymentMethod = this.scheduleEditForm.paymentMethod;
          scheduleDate = this.scheduleEditForm.scheduleDate;
        }
        
        return <EditRecurringScheduleModal 
          convenienceFee={rconvenienceFee}
          amount={ramount}
          recurringId={this.selectedScheduledPayment.recurringId}
          scheduleDate={rscheduleDate}
          loanNumber={this.props.selectedLoanAccount.loanNo}
          loanId={this.props.selectedLoanAccount.id}
          showLoading={showLoading}
          hideLoading={hideLoading}
          regularPmtAmt={this.props.selectedLoanAccount.regularPmtAmt}
          paymentMethods={this.props.cards}
          selectedPaymentMethod={rpaymentMethod}
          borSchedRecurringAchOnly={this.props.client.borSchedRecurringAchOnly}
          borAchRealtimePmts={this.props.client.borAchRealtimePmts}
          borAchScheduleBizDays={this.props.client.borAchScheduleBizDays}
          onSubmit={this.handleEditScheduledPayment}
          whiteLabel={this.props.whiteLabel} />
      case 'EDIT_SCHEDULE_CARD_TERMS':
        let clientData = this.props.client;

        return <EditScheduleCardTerms
          amount={this.scheduleEditForm.totalAmount}
          company={{
            name: clientData.legalCompanyName,
            website: clientData.website,
            phone: clientData.phoneNumber,
            workingHour: clientData.workingHour
          }}
          lastFour={this.scheduleEditForm.paymentMethod ? this.scheduleEditForm.paymentMethod.lastFour : null}
          scheduleDate={moment(this.scheduleEditForm.scheduleDate).format('MM/DD/YYYY')}
          onCancel={this.handleCancelEditScheduleAchTerms} 
          onSubmit={this.editScheduledPayment}
          whiteLabel={this.props.whiteLabel}
          disclaimer={this.props.disclaimer} />
      case 'EDIT_SCHEDULE_ACH_TERMS' :
        let { client } = this.props;

        return <EditScheduleAchTerms
          amount={this.scheduleEditForm.totalAmount}
          company={{
            name: client.legalCompanyName,
            website: client.website,
            phone: client.phoneNumber,
            workingHour: client.workingHour
          }}
          lastFour={this.scheduleEditForm.paymentMethod ? this.scheduleEditForm.paymentMethod.lastFour : null}
          scheduleDate={moment(this.scheduleEditForm.scheduleDate).format('MM/DD/YYYY')}
          onCancel={this.handleCancelEditScheduleAchTerms} 
          onSubmit={this.editScheduledPayment}
          whiteLabel={this.props.whiteLabel}
          disclaimer={this.props.disclaimer} />
        case 'EDIT_SCHEDULE_PAD_TERMS' :
          let { cli } = this.props;
  
          return <EditSchedulePadTerms
            amount={this.scheduleEditForm.totalAmount}
            company={{
              name: cli.legalCompanyName,
              website: cli.website,
              phone: cli.phoneNumber,
              workingHour: cli.workingHour
            }}
            lastFour={this.scheduleEditForm.paymentMethod ? this.scheduleEditForm.paymentMethod.lastFour : null}
            scheduleDate={moment(this.scheduleEditForm.scheduleDate).format('MM/DD/YYYY')}
            onCancel={this.handleCancelEditScheduleAchTerms} 
            onSubmit={this.editScheduledPayment}
            whiteLabel={this.props.whiteLabel}
            disclaimer={this.props.disclaimer} />
      default :
        return null;
    }
  }

  renderModal() {
    return (
      <Modal 
        isOpen={this.state.isModalOpen}
        width={this.state.modalWidth}
        onClose={this.closeModal}
        onClosing={this.getHandlerOnClosing()}>
        { this.renderModalContent() }
      </Modal>
    );
  }

  
  /**
   * render the form to schedule a payment.
   */
  renderScheduleForm() {
    var newPaymentMethods = this.props.cards
    //This validation is for showing only ach payment methods when the payment frequency is different than onlyonce
    //and the flag borSchedRecurringAchOnly is true at schedule payments
    if (this.props.client.borSchedRecurringAchOnly && this.state.paymentFrequency !== 'onlyonce') {
      newPaymentMethods = newPaymentMethods.filter((itm,indx)=>{return itm.paymentType == 2})
    }
    return (
      <ScheduleForm 
        resetSelectedPayment={this.clearPaymentMethod}
        loanAccount={this.props.selectedLoanAccount}
        onChangeAmount={this.handleChangeAmount}
        selectedPaymentMethod={this.state.selectedPaymentMethod}
        onSuccess={this.handleSuccessSchedule}
        hasRecurring={this.loanHasRecurring()}
        editAmount={this.props.client.scheduleCustAmt}
        borSchedMaxDays={this.props.client.borSchedMaxDays}
        restrictSchMaxDaysForFrequencies={this.props.client.restrictSchMaxDaysForFrequencies}
        notifyPmtLessThanDue={this.props.client.notifyPmtLessThanDue}
        delete={this.props.client.scheduleDelete}
        autoFillSchOnetime={this.props.client.autoFillSchOnetime}
        showRemoveRecurring={this.props.client.collectorScheduledDelete && this.props.client.scheduleDelete}
        changeFrequency={this.handleChangePaymentFrequency}
        paymentMethods={
          <PaymentMethods 
            paymentMethods={newPaymentMethods} 
            selected={this.state.selectedPaymentMethod}
            onChange={this.handleChangePaymentMethod}
            onAddPaymentMethod={this.handleAddPaymentMethod}
            syncWithStore={false} />
        }
      />
    );
  }

  /**
   * Render the component view.
   */
  render() {
    return (
      <div>
        {/**
          ** Options & Filters
          **/}    
        <div className="page-options">
          <div className="page-options__content">
            { this.props.client.useLmsSchedules ? ('') : (<Status selected={this.props.status} onChange={this.handleSelectStatus} />) }
            <PaymentTypes onSelectedPaymentType={this.handleSelectPaymentType} />
            <div className="lmargin-auto">
              <SortBy whiteLabel={this.props.whiteLabel} selected={this.props.sortBy} onChange={this.handleSelectSortBy} />
            </div>
          </div>
        </div>

        <div className="content app-schedule">
          {/**
            ** Loan account.
            **/}
          <div className="app-schedule__load-account">
            {this.props.loanAccounts !== null &&
              <LoanAccount
                isSchedule={true}
                onLinkLoanAccountSuccess={this.handleLinkLoanSuccess}
                onLoanInfoSuccess={this.fetchInitialData}
                onAddPaymentMethodSuccess={this.fetchInitialData}
                form={this.renderScheduleForm()} 
              />
            }
          </div>

          {/**
            ** Payment schedules list.
            **/}
          { (this.props.scheduledPayments.length > 0)
            ? (
              <div className="app-schedule__receipts">
                { this.props.scheduledPayments.map(schedulePayment => 
                  <div className="app-schedule__receipt" key={schedulePayment.id}>
                    <ScheduleReceipt 
                      enableLmsUi={this.props.client.enableLmsUi}
                      whiteLabel={this.props.whiteLabel}
                      id={(parseInt(this.props.client.useLmsSchedules) === 1 && schedulePayment.type == 2) 
                        ? this.props.scheduledPayments.filter((itm,indx)=>{return itm.recurringId == schedulePayment.recurringId})[0].id 
                        : schedulePayment.id}
                      recurringId={schedulePayment.recurringId}
                      amount={schedulePayment.pmtAmt}
                      date={schedulePayment.nextExecutionDate}
                      scheduleType={schedulePayment.type}
                      frequency={schedulePayment.paymentText}
                      loanAccount={schedulePayment.loan}
                      status={schedulePayment.status}
                      isFinal={schedulePayment.isFinal}
                      isFinalSchedule={this.props.client.isFinalSchedule}
                      convenienceFee={schedulePayment.convenienceFee}
                      paymentMethod={this.getPaymentMethod(schedulePayment)}
                      onEdit={this.handleEdit}
                      onRecurringEdit={this.handleRecurringEdit}
                      onDelete={this.handleDelete}
                      onRecurringDelete={this.handleRecurringDelete}
                      useLmsSchedules={this.props.client.useLmsSchedules}
                      can={{
                        edit: (schedulePayment.role === 2) ? this.props.client.collectorScheduledEdit : this.props.client.scheduleEdit,
                        delete: (schedulePayment.role === 2) ? this.props.client.collectorScheduledDelete : this.props.client.scheduleDelete
                      }} />
                  </div>
                ) }
              </div>
            ) 
            : this.renderEmptyScheduledPayments() 
          }
        </div>
        
        {/**
          ** Success Schedule Modal
          **/}
        <Modal 
          isOpen={this.state.isSuccessScheduleModalOpen}
          onClose={ this.closeSuccessScheduleModal }>
          <CongratulationsScheduleModal
            {...this.mapResponseToPropsScheduleSuccessModal()}
            onClose={ this.closeSuccessScheduleModal}
            whiteLabel={this.props.whiteLabel} />
        </Modal>

        {/**
          ** Delete schedule payment Modal
          **/}
        <Modal 
          isOpen={this.state.isDeleteScheduledPaymentModalOpen}
          onClose={ this.closeDeleteScheduledPaymentModal }>
          <DeleteScheduledPaymentModal
            onCancel={this.closeDeleteScheduledPaymentModal}
            onDelete={this.handleDeleteScheduledPayment}
            achText={(
              this.props.client.achCancelAlert == 1 &&
              (this.selectedScheduledPayment !== null 
              && this.selectedScheduledPayment.paymentType == 2 
              && this.selectedScheduledPayment.type == 2)) 
              ? this.props.whiteLabel.labels['ach_recurring_cancel_alert_text'] : ''}
            whiteLabel={this.props.whiteLabel} />
        </Modal>

        {/**
          ** Delete schedule payment Modal
          **/}
        <Modal 
          isOpen={this.state.isDeleteRecurringScheduledPaymentModalOpen}
          onClose={ this.closeDeleteRecurringScheduledPaymentModal }>
          <DeleteRecurringScheduledPaymentModal
            onCancel={this.closeDeleteRecurringScheduledPaymentModal}
            onRecurringDelete={this.handleDeleteScheduledPayment}
            whiteLabel={this.props.whiteLabel} />
        </Modal>

        { this.renderModal() }
      </div>
    );
  }
}

const loanAccountService = new LoanAccountService();

const mapStateToProps = state => {
  let paymentMethod = findById(state.app.payment.selectedMethod, state.client.cards);

  let paymentMethods = state.client.cards;
  if (state.client.data.hideCardsForBorrowers === '1') {
    paymentMethods = state.client.cards.filter((itm, indx) => { return itm.paymentType == 2 });
  }

  let { whiteLabel } = state;
  return {
    cards: paymentMethods,
    client: state.client.data,
    loanAccounts: state.app.loanAccounts,
    paymentType: state.schedule.paymentType,
    scheduledPayments: state.schedule.scheduledPayments,
    selectedLoanAccount: loanAccountService.get(state.app.selectedLoanAccount),
    sortBy: state.schedule.sortBy,
    status: state.schedule.status,
    whiteLabel:whiteLabel,
    paymentMethod,
    disclaimer:{
      borrowerOnetimeCardDisclosure: state.client.data.borrowerOnetimeCardDisclosure,
      borrowerRecurringCardDisclosure: state.client.data.borrowerRecurringCardDisclosure,
      borrowerOnetimeAchDisclosure: state.client.data.borrowerOnetimeAchDisclosure,
      borrowerRecurringAchDisclosure: state.client.data.borrowerRecurringAchDisclosure
    },
    loading: state.app.loading,
    hideCardsForBorrowers: state.client.data.hideCardsForBorrowers,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showLoading, hideLoading, setOption, fetchScheduledPayments, 
    setScheduledPayments, setPaymentMethod, setConvenienceFee, 
    fetchLoanAccounts, fetchPaymentCards, setSelectedLoanAccount, getTransactionMargin, 
    getClientImages, getClientDetails
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Schedule));
