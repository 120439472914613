import { HOST, ENDPOINTS } from '../constants/api';
import axios from 'axios';
const { getClientDetails, getBackGroundImage, paymentFrequencies, getClientInfo } = ENDPOINTS;

export function fetchClientDetails($loan_no) {
  return axios({
    url: `${HOST}${getClientDetails}?loan_no=${$loan_no}&lang=${localStorage.getItem('language')}`,
    method: 'get'
  });
}

export function fetchPaymentFrequencies() {
  return axios({
    url: `${HOST}${paymentFrequencies}`,
    method: 'get'
  });
}


export function fetchBackgroundImage() {
  return axios({
    url: `${HOST}${getBackGroundImage}`,
    method: 'get'
  });
}

export function getClientImages($loan_no) {
  return axios({
    url: `${HOST}${getClientInfo}?loan_no=${$loan_no}`,
    method: 'get'
  });
}

/**
 * Get the client details, including the emails.
 */
export function getDetails() {
  let endpoint = ENDPOINTS.userDetails;

  return window.axios.post(endpoint);
}

/**
 * Add a email to the client account.
 */
export function requestAddEmail(email) {
  let endpoint = ENDPOINTS.addEmail;

  return window.axios.post(endpoint, email);
}

/**
 * Add a phone number to the client account.
 */
export function requestAddPhone(phoneno) {
  let endpoint = ENDPOINTS.addPhone;

  return window.axios.post(endpoint, phoneno);
}

/**
 * Delete a email to the client account.
 */
export function requestDeleteEmail(id) {
  let endpoint = ENDPOINTS.deleteEmail;

  return window.axios.post(endpoint, {
    emailId: id
  });
}

/**
 * Delete a phone number from the client account.
 */
export function requestDeletePhone(id) {
  let endpoint = ENDPOINTS.deletePhone;

  return window.axios.post(endpoint, {
    phoneId: id
  });
}

/**
 * Setup a email as primary for a loan account.
 */
export function requestMakeEmailAsPrimary(data) {
  let endpoint = ENDPOINTS.makePrimary;

  return window.axios.post(endpoint, data);
}

/**
 * Setup a phone number as primary for a loan account.
 */
export function requestMakePhoneAsPrimary(data) {
  let endpoint = ENDPOINTS.makePrimaryPhone;

  return window.axios.post(endpoint, data);
}

/**
 * Resend the email confirmation.
 */
export function requestResendEmailConfirmation(id) {
  let endpoint = ENDPOINTS.resendLink;

  return window.axios.post(endpoint, {
    emailId: id
  });
}

/**
 * Update the contact information.
 */
export function requestUpdateContactInfo(data) {
  let endpoint = ENDPOINTS.updateDetails;

  return window.axios.post(endpoint, data);
}


/**
 * Make a request to fetch the user cards.
 */
export function requestAllCards() {
  let endpoint = ENDPOINTS.listCards + '?limit=10&offset=0';

  return window.axios.get(endpoint);
}

/**
 * Make a request to add a new card.
 * 
 * @param {*} payload 
 */
export function addCard(payload) {
  let endpoint = ENDPOINTS.addCard;

  return window.axios.post(endpoint, payload);
}

/**
 * Make a request to add a new ach account.
 * 
 * @param {*} payload 
 */
export function addAch(payload) {
  let endpoint = ENDPOINTS.addAccount;

  return window.axios.post(endpoint, payload);
}

/**
 * Make a request to delete a specific card or ach account.
 * 
 * @param id - The card id. 
 * @param type - The payment type.
 */
export function requestDeleteCard(id, type) {
  let endpoint = ENDPOINTS.removeCard;
  let data = {
    cardId: id
  };

  if (type === 2) {
    endpoint = ENDPOINTS.removeAccount;
    data = {
      accountId: id
    }
  }

  if (type === 3) {
    endpoint = ENDPOINTS.removeAccount;
    data = {
      isPad: 1,
      accountId: id
    }
  }

  return window.axios.post(endpoint, data);
}

/**
 * Check is a card is valid or not.
 * 
 * @param {*} number 
 */
export function checkCard(number) {
  let endpoint = ENDPOINTS.checkValidCard + '?cardNumber=' + number;

  return window.axios.get(endpoint);
}

export function requestTransactionMargin(params) {
  let endpoint = ENDPOINTS.transactionMargin;

  return window.axios.get(endpoint);
}

/**
 * Send the signup verification code by phone call
 * 
 * @param {*} number 
 */
export function sendCodeByPhone(phone) {
    let endpoint = ENDPOINTS.sendCodeByPhone;

    return window.axios.post(endpoint, {
      phoneNo: phone,
      lang: localStorage.getItem('language')
    });
}

export function requestClientBorrowerMessages(){
  return window.axios.get(ENDPOINTS.getClientBorrowerMessages);
}


/**
 * Make a request to change email of the borrower before login for the first time
 * 
 * @param {*} payload 
 */
 export function changeEmail(payload) {
  let endpoint = ENDPOINTS.changeEmail;

  return window.axios.post(endpoint, payload);
}

export function requestConstantAiUrl(params) {
  return window.axios.post(ENDPOINTS.getConstantAiUrl, params);
}
