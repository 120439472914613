import React, { Component } from 'react';
import moment from 'moment';
import PayixButton from '../../containers/payix-button';
import PaymentMethods from '../../components/application/payment-methods';
import { toUSD, checkDecimal } from '../../helpers/formatters';
import { requestConvenienceFee } from '../../api/payment';
import { isOkHttpResponse } from '../../helpers/app';
import store from '../../config/store';

class EditRecurringScheduleModal extends Component {
  /**
   * Receive the props and init the state.
   */
  constructor(props) {
    super(props);
    this.state = {
      convenienceFee: props.convenienceFee,
      amount: props.amount,
      scheduleDate: moment(this.props.scheduleDate),
      paymentMethod: props.selectedPaymentMethod,
      recurringId: props.recurringId
    }
  }
  
  /**
   * Handler for on change event on the amount field.
   * 
   * @param event
   */
  handleChangeAmount = (event) => {
    requestConvenienceFee(
      this.props.loanId,
      this.state.paymentMethod["cardBrand"],
      this.state.paymentMethod.paymentType,
      this.props.frequency,
      this.state.paymentMethod.id,
      event.target.value
    ).then((response) => {
      let data = response.data;
      if (data.status === 1 && isOkHttpResponse(data.statusCode)) {
        this.setState({
          convenienceFee: data.result ? data.result.transactionFee : 0,
        });
      }
    }).catch((error) => {
    });
    this.setAmount(event.target.value);
  }

  /**
   * Set the new value for amount.
   * 
   * @param value
   */
  setAmount = (value) => {
    value = checkDecimal(value);

    if (value) {
      this.setState({
        amount: value[0]
      });
    }
  }

  /**
   * Set the selected schedule date.
   * 
   * @param selectedDated An array with the selected dates.
   * @param dateStr - The date in string format.
   */
  setScheduleDate = (date) => {
    this.setState({
      scheduleDate: date
    });
  }

  /**
   * Handle when payment method is changed.
   * 
   * @param id - The id of the selected payment method.
   */
  handleChangePaymentMethod = (index) => {
    let paymentMethod = this.props.paymentMethods[index];
    store.dispatch(this.props.showLoading());
    requestConvenienceFee(
      this.props.loanId,
      paymentMethod["cardBrand"],
      paymentMethod.paymentType,
      this.props.frequency,
      paymentMethod.id,
      this.state.amount
    ).then((response) => {
        let data = response.data;
        store.dispatch(this.props.hideLoading());
        if (data.status === 1 && isOkHttpResponse(data.statusCode)) {
          this.setState({
            convenienceFee: data.result ? data.result.transactionFee : 0,
          });
        }
      }).catch((error) => {
        store.dispatch(this.props.hideLoading());
      });
    this.setState({
      paymentMethod: paymentMethod,
    });
  }

  /**
   * Get the total amount to pay.
   */
  getAmount() {
    let amount = this.state.amount === '' ? 0 : parseFloat(this.state.amount) + parseFloat(this.state.convenienceFee);
    return parseFloat(amount);
  }

  /**
   * Check if all form's fields are filled.
   */
  isFormFull() {
    let { amount, paymentMethod, scheduleDate } = this.state;
    
    if (amount && paymentMethod && scheduleDate) {
      return true;
    }

    return false;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.onSubmit({
      amount: parseFloat(this.state.amount).toFixed(2),
      totalAmount: this.getAmount(),
      scheduleDate: moment(this.state.scheduleDate).format('YYYY-MM-DD'),
      paymentMethod: this.state.paymentMethod,
      recurringId: this.state.recurringId
    });
  }

  /**
   * Render a dropdown with the payment methods.
   */
  renderPaymentMethods = () => {
    if (this.props.borSchedRecurringAchOnly) {
      return (<PaymentMethods
        paymentMethods={this.props.paymentMethods.filter((itm,indx)=>{return itm.paymentType === 2})}
        selected={this.state.paymentMethod}
        onChange={this.handleChangePaymentMethod}
        syncWithStore={false}
        allowAddNew={false} />)
    }
    return (
      <PaymentMethods
        paymentMethods={this.props.paymentMethods}
        selected={this.state.paymentMethod}
        onChange={this.handleChangePaymentMethod}
        syncWithStore={false}
        allowAddNew={false} />
    )
  }

  /**
   * Render the component view.
   */
  render() {

    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <h3 className="modal-title common-title">
            {this.props.whiteLabel.labels['Edit Recurring Schedule']}
            </h3>
          </div>
        </div>
        
        <div className="modal__body">
          <div className="modal__body-content--pad35">
            <div>
              <div className="flex-block flex-block--space-between">
                <div className="line-height-15">
                  <span className="money money--block">{this.props.loanNumber}</span>
                  <span className="load-account__money-legend">{this.props.whiteLabel.labels['Loan Number']}</span>
                </div>

                <div className="align-right line-height-15">
                  <span className="money money--block">
                    {toUSD(this.props.regularPmtAmt)}
                  </span>
                  <span className="load-account__money-legend">{this.props.whiteLabel.labels['Regular Payment']}</span>
                </div>
              </div>

              <form onSubmit={this.handleSubmit}>
                <hr className="hr" />
                { this.renderPaymentMethods() }
                <hr className="hr" />

                <div className="form-group margin-top-15">
                  <label className="form-group__label">{this.props.whiteLabel.labels['Enter amount you would like to pay']}</label>
                  <div>
                    {/**
                      ** Render the input for the amount.
                      **/}
                    <input 
                      className="money-input-simple" 
                      type="text" 
                      maxLength="13"
                      value={this.state.amount}
                      onChange={this.handleChangeAmount} />
                  </div>
                </div>

                <div className="load-account__total">
                  <span className="gothammedium-font">{this.props.whiteLabel.labels['Convenience Fee']}</span>
                  <span className="gothammedium-font">{toUSD(this.state.convenienceFee)}</span>
                </div>
                
                <div className="load-account__total">
                  <span className="gothammedium-font">{this.props.whiteLabel.labels['Total Payable']}</span>
                  <span className="gothammedium-font">{toUSD(this.getAmount())}</span>
                </div>

                <hr className="hr" />

                <div className="load-account__total">
                  <span className="form-group__label">{this.props.whiteLabel.labels['Submitting this will overwrite the amount and payment method on all schedules in Group:']} {this.props.recurringId}</span>
                </div>


                <div className="form-group margin-top-20">
                  <PayixButton className="button--full" type="submit" disabled={!this.isFormFull()}>
                  {this.props.whiteLabel.labels['Edit Recurring Schedule']}
                  </PayixButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
 
export default EditRecurringScheduleModal;